<template>

  <div class="w-100">

        <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="7%" class="text-center">Codigo</th>
                    <th width="43%" class="text-center">Nombre</th>
                    <th width="12%" class="text-center">UM</th>
      
                    <th width="9%" class="text-center">Cantidad</th>
                    <th width="10%" class="text-center">P. Unit</th>
                    <th width="7%" class="text-center">P. Total</th>
                    <th width="5%" class="text-center">Acc.</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in sale_detail" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-center">{{ item.code }}</td>
                      <td class="align-middle text-left">
                        <b-input size="sm" type="text" v-model="item.name"></b-input>
                      </td>
                      <td class="align-middle text-center">{{ NameUnitMeasure(item.unit_measure) }}</td>
 
                      <td class="align-middle text-center">
                        <b-input size="sm" :disabled="type_invoice == '07' && !(reason == '07')" step="any" type="number" @change="EditDetail(it)" class="form-control text-right" v-model="item.quantity"></b-input>
                      </td>
                      <td class="align-middle text-center">
                        <b-input size="sm" :disabled="(type_invoice == '07')" type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.unit_price"></b-input>
                      </td>
                      <td class="align-middle text-right">{{ item.total_price }}</td>
                      <td class="align-middle text-center">
                        <b-form-checkbox size="sm" :disabled="type_invoice == '07' && !(reason == '07')" @change="StateChecked" :id="'checkbox-'+ it"  v-model="item.state" :name="'checkbox-'+ it" value="1" unchecked-value="0"></b-form-checkbox>
                      </td>

                  </tr>
                </tbody>
              </table>
        </div>
  </div>
</template>
<style scoped>
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from '@/assets/js/EventBus';
import CodeToName from "@/assets/js/CodeToName";

export default {
  name: "SaleDetail",
   props: ["type_invoice","reason"],

  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    EditDetail,
    DeleteDetail,
    NameUnitMeasure,
    StateChecked,
    ...mapActions('Sale',['mLoadEditSaleDetail']),
    ...mapActions('Sale',['mLoadDeleteSaleDetail']),
    ...mapActions('Sale',['mLoadTotalSaleDetail']),
    
  },
  computed: {
    ...mapState('Sale',['sale_detail']),
    
    
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function StateChecked() {
  this.mLoadTotalSaleDetail();
  EventBus.$emit('TotalPaymentCash');
  EventBus.$emit('ChangeFeesCollectedModal');
}

function EditDetail(index) {
  let name = this.sale_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha modificado el producto ' + name, type: 'warn'})
  this.mLoadEditSaleDetail(index);
  EventBus.$emit('TotalPaymentCash');
  EventBus.$emit('ChangeFeesCollectedModal');
}

function DeleteDetail(index) {
  let name = this.sale_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha eliminado el producto ' + name, type: 'warn'})
  this.mLoadDeleteSaleDetail(index);
  EventBus.$emit('TotalPaymentCash');
  EventBus.$emit('ChangeFeesCollectedModal');
}
</script>
