<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modalProducts" class="w-100" title="Productos">
      <b-row>
        <b-col md="2">
          <b-form-group label="Categoria :">
            <b-form-select v-model="id_category_letter" :options="categories" @change="SearchProducts"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="8">
          <b-form-group >
            <b-form-checkbox class="mb-2" switch size="sm" @change="SearchProducts" v-model="type_search" name="check-button" >Buscar por producto/composición:</b-form-checkbox>
            <b-form-input  type="text"  ref="email" autofocus v-model="search_product" @keyup="SearchProducts"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label=".">
              <b-form-checkbox @change="SearchProducts" v-model="stock" value="1" unchecked-value="0">
                solo con stock
              </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th width="3%"  rowspan="2" class="text-center align-middle">#</th>
                  <th width="8%"  rowspan="2" class="text-center align-middle">Código</th>
                  <th width="37%"  rowspan="2" class="text-center align-middle">Nombre</th>
                  <th width="13%"  rowspan="2" class="text-center align-middle">U. M.</th>
                  <th width="8%"  rowspan="2" class="text-center align-middle">Cantidad</th>
                  <th width="10%"  rowspan="2" class="text-center align-middle">P. Unit.</th>
                  <th width="5%"  rowspan="2" class="text-center align-middle">Acc.</th>
                </tr>
     
              </thead>
              <tbody v-for="(item, it) in products" :key="it">
                <tr>
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-left">{{ item.code }}</td>
                  <td class="text-left">
                    {{ item.name + (item.presentation.length == 0 ? "":" - "+item.presentation)   }} <br>
                  </td>
                  <td class="text-center">{{ NameUnitMeasure(item.unit_measure) }}</td>
                  <td class="text-center">
                    <input type="number" step="any" value="1.00" :ref="'mSDCantidad'+item.id_letter" class="form-control text-right">
                  </td>
                  <td class="text-center">
                    <input type="number" step="any" :value="item.sale_price" :ref="'mSDPUnit'+item.id_letter" class="form-control text-right">
                  </td>
                  <td class="text-center">
                      <b-button type="button" @click="AddProduct(item.id_letter)" variant="primary">
                        <i class="fas fa-plus-square"></i>
                      </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-100">
            <span class="text-success">P. Comisionables</span> | <span class="text-warning">P. Internos</span>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
</style>


<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// import Notifications from 'vue-notification/dist/ssr.js';
import CodeToName from "@/assets/js/CodeToName";

export default {
  name: "ModalsProduct",
  data() {
    return {
        modalProducts:false,
        module:'Sale',
        role:0,
        id_establishment:0,
        type_search:false,
        search_product:'',
        stock:0,
        categories:[],
        id_category_letter:'all',
        products: [],
        establishment:{},
        warehouses:[]
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalProductsShow', (role) => {
      console.log(role)
      this.modalProducts = true;
      this.role = role;
      this.ListCategories();
    });
    EventBus.$on('RefreshModalProducts', () => {
      this.SearchProducts();
    });
    
  },
  methods: {
      SearchProducts,
      AddProduct,
      BackgroundColor,
      NameUnitMeasure,
      ListCategories,

        ...mapActions('Sale',['mLoadAddSaleDetail']),
      
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function BackgroundColor(internal_product,commissionable) {
  if (commissionable == 1) {
    return 'bg-success';
  }
  if (internal_product == 1) {
    return 'bg-warning';
  }
  return '';
}

function ListCategories() {
  let me = this;
  let url = this.url_base + "category-letter/list-active";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
    .then(function (response) {
      me.categories = [{value:'all',text:'Todos'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.categories.push({value:element.id_category_letter,text:element.name})
        }
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function AddProduct(id_letter) {
  
    let quantity = this.$refs['mSDCantidad'+id_letter][0]['value'];
    let unit_price = this.$refs['mSDPUnit'+id_letter][0]['value'];
    let me = this;
    let url = this.url_base + "letter/view/" + id_letter;
    axios({
      method: "GET",
      url: url,
      headers: { token: this.token, module: this.module,role: this.role,},
    })
    .then(function (response) {
      if (response.data.status == 200) {
        me.search_product = "";
        let total_price = parseFloat(unit_price) * parseFloat(quantity);
        let detail = {
          id_order_detail: 0,
          id_letter: response.data.result.letter.id_letter,
          code: response.data.result.letter.code,
          name: response.data.result.letter.name,
          unit_measure: response.data.result.letter.unit_measure,
          igv: response.data.result.letter.igv,
          existence_type: response.data.result.letter.existence_type,
          quantity: parseFloat(quantity).toFixed(2),
          unit_price: parseFloat(unit_price).toFixed(2),
          total_price: parseFloat(total_price).toFixed(2),
          state: 1,
        }
        me.mLoadAddSaleDetail(detail);
    
        me.$notify({ group: 'alert', title: 'Sistema', text:'Se ha agregado el producto '+detail.name + ' - '+detail.presentation , type: 'success'});
        EventBus.$emit('TotalPaymentCash');
        EventBus.$emit('ChangeFeesCollectedModal');
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    }).catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}
//Buscar productos
function SearchProducts() {
  
  let me = this;
  let search = this.search_product == "" ? "all" : this.search_product;
  let url = this.url_base + "letter/search-by-category";
  let data = {
    id_category_letter: this.id_category_letter,
    type_stock : 'all',
    search : search,
  };
  axios({
    method: "POST",
    data: data,
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.products = response.data.result;
      } else {
        me.products = [];
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      
    });
}

</script>
